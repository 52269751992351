@import "@fortawesome/fontawesome-svg-core/styles.css";

@tailwind base;

@layer base {
  /* Table resets */
  th {
    font-weight: inherit;
  }
}

@tailwind components;
@tailwind utilities;

@layer components {
  /**
  * Common component classes defined below.
  * 
  * Classes defined below do NOT show up via Intellisense when coding and
  * therefore, we want to follow a simple naming scheme. As of right now, this
  * pattern will be just taking the Ariakit component name the class is 
  * expected to be paired with and hypenating it e.g. `MenuItem` becomes
  * `menu-item`.
  *
  * These classes could be defined using plugins in the tailwind config file,
  * and using something like https://github.com/softwarecrafts/tailwind-component-classes
  * to improve the DX of actually defining them in the config file, to get back
  * Intellisense but using `@apply` in plugins is discouraged by the creator of
  * Tailwind (see https://github.com/tailwindlabs/tailwindcss/discussions/11012#discussioncomment-5653785).
  * This means we would have to define these component classes using CSS-in-JS
  * which is clunky. Not only that but the flow for us would likely be to use
  * normal tailwind classes in code and eventually move them here. By doing
  * CSS-in-JS, it would require translating the existing styling we already did
  * via tailwind into CSS which could dramatically slow that process as well as
  * be error prone.
  *
  * We can revisit this but this should at least be a good starting point for us
  * to start having commonly styled components.
  */

  .menu {
    @apply z-50 mt-1 min-w-[250px] rounded-lg border border-zinc-100 bg-white py-2 text-sm text-zinc-900 shadow-xl ring-1 ring-black/20;
  }

  .menu-item {
    @apply flex cursor-pointer items-center gap-2 py-2 pl-3 pr-6 text-sm font-normal leading-none hover:bg-gray-100;
  }

  .select {
    @apply relative flex h-9 cursor-default flex-wrap content-center rounded-md bg-white px-2 py-1.5 text-left text-sm ring-1 ring-inset ring-gray-300 aria-expanded:ring-2 aria-expanded:ring-indigo-600 hover:ring-2 focus:outline-none focus:ring-2 focus:ring-indigo-600;
  }

  .select-arrow {
    @apply absolute right-2 top-2 flex items-center justify-center;
  }

  .select-popover {
    @apply z-50 mt-1 w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black/5 focus:outline-none;
  }

  .toolbar-item {
    @apply flex select-none scroll-mx-1 items-center justify-center gap-2 whitespace-nowrap rounded border-none bg-gray-50 p-3 leading-4 text-black no-underline shadow-black outline-2 outline-offset-2 outline-sky-600 aria-disabled:opacity-50 aria-pressed:bg-gray-200 hover:bg-gray-200 focus-visible:outline active:shadow-inner;
  }

  .tooltip {
    @apply rounded bg-black px-2 py-1 text-xs text-white;
  }
}
